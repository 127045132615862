// Bootstrap Scss //
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'font-awesome/css/font-awesome.min.css';

// ico icon
@import './vendors/icofont.scss';

//LeafLet
@import "~leaflet/dist/leaflet.css";

// Carousel
@import "react-responsive-carousel/lib/styles/carousel.min.css";

// Themefiy Awesome
@import './vendors/themify/themify-icons';

// Slick Slider Scss //
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// Feather icons
@import './vendors/feather-icon/feather-icon';

//easymde
@import "easymde/dist/easymde.min.css";

// simplebar
@import 'simplebar-react/dist/simplebar.min.css';

// react-image-crop
@import 'react-image-crop/src/ReactCrop.scss';

//filepond
@import 'filepond/dist/filepond.min.css';

//react-dropzone-uploader
@import 'react-dropzone-uploader/dist/styles.css';

//react-perfect-scrollbar
@import "react-perfect-scrollbar/dist/css/styles.css";

//react-bootstrap-typeahead
@import 'react-bootstrap-typeahead/css/Typeahead.css';

// ReactToastify
@import '~react-toastify/dist/ReactToastify.css';

// Carousel
@import "react-responsive-carousel/lib/styles/carousel.min.css";

// WeatherIcons
@import './vendors/whether-icon.scss';

// Flag icon
@import './vendors/flag-icon.scss';
@import "/node_modules/flag-icons/css/flag-icons.min.css";

// react date-piker
@import "react-datepicker/dist/react-datepicker.css";

// Timeline-calender
@import "react-calendar-timeline/lib/Timeline.css";

//react-calendar
@import 'react-calendar/dist/Calendar.css';

// react-photoswipe-gallery
@import 'photoswipe/dist/photoswipe.css';

// react-simplemde-editor
@import "easymde/dist/easymde.min.css";

// Style Scss //
@import './style.scss';

// Responsive Scss //
@import './responsive.scss';