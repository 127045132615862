/**=====================
  2.18 Forms CSS Start
==========================**/
.form-label {
  margin-bottom: 4px;
}

.col-form-label {
  padding-bottom: 4px;
}

.form-space {
  margin-bottom: -10px;

  >div {
    margin-bottom: 10px;
  } 
}

.theme-form {
  .form-control {
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
    }
  }

  textarea {
    border-color: $form-input-border-color;

    &::-webkit-input-placeholder {
      color: $dark-gray;
      font-size: $form-placeholder-font-size;
      letter-spacing: $form-placeholder-letter-spacing;
      font-weight: $form-placeholder-font-weight;
    }
  }

  input[type=text],
  input[type=email],
  input[type=search],
  input[type=password],
  input[type=number],
  input[type=tel],
  input[type=date],
  input[type=datetime-local],
  input[type=time],
  input[type=datetime-local],
  input[type=month],
  input[type=week],
  input[type=url],
  input[type=file],
  textarea,
  select {
    &::placeholder{ 
      color: $form-placeholder-color; 
      font-weight: 500;
    }
    border-color: $gray-60;
    background-color: $form-input-bg-color;
    font-size: $form-placeholder-font-size;
    color: $form-placeholder-color;
    padding: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    
    &::-webkit-input-placeholder {
      color:  $light-txt-color;
      font-size: $form-placeholder-font-size;
      letter-spacing: $form-placeholder-letter-spacing;
      font-weight: $form-placeholder-font-weight;
    }
  }
  
  input[type=text]{
    &::placeholder{ 
      color: $light-txt-color;
      font-size: 14px; 
    }
  }

  input[type=file] {
    height: auto;
  }

  select.form-control:not([size]):not([multiple]) {
    border-color: $form-input-border-color;
    font-size: $form-placeholder-font-size;
  }

  .checkbox {
    label {
      padding-left: 10px;

      [dir="rtl"] & {
        padding-right: 10px;
        padding-left: unset;
      }
    }
  }

  .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;

    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      content: "Or Signup With";
      color: $theme-font-color;
      background: $white;

      [dir="rtl"] & {
        left: unset;
        right: 40%;
        margin-left: unset;
        margin-right: -10px;
      }
    }
  }
  

  .login-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;

    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      color: $theme-font-color;
      background: $white;
      content: "Or Login With";

      [dir="rtl"] & {
        left: unset;
        right: 40%;
        margin-left: unset;
        margin-right: -10px;
      }
    }
  }

  .btn-fb,
  .btn-twitter,
  .btn-google,
  .btn-linkedin {
    color: $white;
    width: 100%;
  }

  .btn-fb {
    background-color: $fb;
  }

  .btn-twitter {
    background-color: $twitter;
  }

  .btn-google {
    background-color: $google-plus;
  }

  .btn-linkedin {
    background-color: $linkedin;
  }
}

.form-inline {
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;

  .col-form-label {
    margin-right: 5px;

    [dir="rtl"] & {
      margin-left: 5px;
      margin-right: unset;
    }
  }
}

.search-form {
  .form-group {
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 25px;
      background: #e8ebf2;
      left: 82px;
      top: 50px;

      [dir="rtl"] & {
        left: unset;
        right: 82px;
      }
    }

    &:after {
      position: absolute;
      content: "\f002";
      font-family: "FontAwesome";
      top: 53px;
      left: 53px;
      color: #8e8e8e;

      [dir="rtl"] & {
        left: unset;
        right: 53px;
      }
    }
  }

  input {
    border: 1px solid $light-semi-gray;
    padding: 10px 10px 10px 70px;
    border-radius: 10px;
    background-color: rgba($light-color, 0.3);


    [dir="rtl"] & {
      padding: 10px 70px 10px 10px;
    }

    &:focus {
      outline: none !important;
      box-shadow: unset;
      border: none;
    }
  }
  .Typeahead-menu{
    &.custom-scrollbar{
      overflow: auto;
      height: 500px;
      @media screen and (max-width: 1199px) {
        min-width: 300px !important;
      }
      @media screen and (max-width: 767.98px) { 
        right: 0px;
        top: 64px;
        // width: !important;
        left: unset;
        min-width: 240px !important;
      }
      @media screen and (max-width: 575px) {
        left: 50%;
        transform: translateX(-50%);
        right: unset;
        min-width: 255px !important;
        top: 60px;
      }
      @media screen and (max-width: 480px) {
        left: 50%; 
        transform: translateX(-6%);
        right: unset;
        min-width: 240px !important;
        top: 60px;
      }
    }
  }
}

.orm-label-align-right {
  label {
    text-align: right;
    padding-top: 17px;
    font-weight: 500;
  }
}

// Custom style
.custom-input {


  &.was-validated {

    .form-control,
    .form-select {
      &:valid {
        border-color: $success-color;
      }

      &:invalid {
        border-color: $danger-color;
      }
    }
  }


  .valid-tooltip {
    background-color: $success-color;
  }

  .invalid-tooltip {
    background-color: rgba($danger-color, 0.8);
  }

  .invalid-feedback {
    color: $danger-color !important;
  }

  .form-check {
    .form-check-input {

      &:valid {
        border-color: $success-color;
      }

      &:invalid {
        border-color: $danger-color;
      }

      &:invalid~.form-check-label {
        color: $danger-color ;
      }

      &:valid~.form-check-label {
        color: $success-color ;
      }

      &:checked {
        background-color: $success-color;
      }
    }

    .invalid-feedback {
      color: $danger-color;
    }
  }

  #validationCustom03,
  #validationCustom05,
  #validationCustomUsername {

    &.form-control,
    .form-select {
      border-color: $gray-60;
    }

    &.was-validated {

      .form-control,
      .form-select {
        &:valid {
          border-color: $success-color !important;
        }
 
        &:invalid {
          border-color: $danger-color !important;
        }

      }
    }
  }

  .form-check {
    &.form-switch {
      .form-check-input {
        [dir="rtl"] & {
          margin-left: 7px;
        }
      }
    }
  }
}
.custom-input{
  .input-group-text{
    height: 58px;
  }
}

// checkbox and radio
.form-check-reverse {
  display: flex; 
  flex-direction: row-reverse;
  justify-content: flex-end; 
  padding: 0;

  .form-check-input {
    float: left;
    margin-right: 0;
    margin-left: 10px;
  }
}


.checkbox-checked {
  &.card-wrapper {
    h6 {
      &.sub-title {
        font-weight: 600;
        font-size: 17px;
      }
    } 
  }

  .form-check-input {
    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &:focus {
      border-color: $primary-color;
      box-shadow: 0 0 0 4px rgba($primary-color, 0.25);
    }
  }

  .card-wrapper {
    .form-check {
      &:last-child {
        min-height: 0;
        margin-bottom: 0;

        label {
          margin-bottom: 0;
        }
      }
    }
  }
}

.form-check-size {
  display: flex;
  gap: 22px;
  align-items: center;
  flex-wrap: wrap;

  .form-check-inline {
    margin: 0;
    min-height: 0;

    [dir="rtl"] & {
      margin: 0;

    }


    .form-check-input {
      width: 18px;
      height: 18px;
      margin-top: 0;
    }

    .check-size {
      width: 36px;
      height: 18px;
      margin-top: 0;
    }

    label {
      margin: 0;
    }
  }
}

// img checkbox
.main-img-checkbox {
  .img-checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-img-cover {
      position: absolute;
      top: 15px;
      left: 15px;
      width: 24px;
      height: 24px;

      [dir="rtl"] & {
        left: unset;
        right: 15px;
      }

    }

    .form-check-label {
      width: 100%;
    }

    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
  }
}

// icons radio button
.main-icon-checkbox {
  &.checkbox {
    label {
      margin-top: 0;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        margin-left: 1px;
        background-color: transparent;
        top: 0;
        display: block;

        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 1px;
        }
      }
    }

  }

  &.checkbox-primary {
    input[type=checkbox]:checked {
      +label::before {
        color: transparent;
        border: 2px solid $primary-color;
      }
    }

    input[type=checkbox] {
      opacity: 1;
    }

    .form-check-input {
      border: 1px solid $gray-60;

      &.checkbox-shadow {
        &:focus {
          box-shadow: none;
        }
      }

      &:checked[type=checkbox] {
        background-image: none;
      }
    }
  }
}

.main-radio-toggle,
.main-checkbox-toggle {
  .btn {
    margin-bottom: 0;
  }
}

.radio-wrapper,
.checkbox-wrapper {
  display: flex;
  gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width:1200px) {
    justify-content: unset;
  }

  li {
    position: relative;
    width: 100%;
    padding: 12px;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 130px;

    @media (max-width:1335px) {
      max-width: 113px;
    }

    @media (max-width:1232px) {
      padding: 10px;
      max-width: 105px;
    }

    .form-check-input {
      position: absolute;
      width: 100%;
      height: 100%;
      float: none;
      margin: 0;
      top: 0;
      left: 0;
      opacity: 1;
      border-radius: 0;
      background-color: transparent;
      border: 1px solid $gray-60;

      &:focus {
        box-shadow: none;
      }

      &[type=radio] {
        opacity: 1;
      }

      &:checked {
        border: 2px solid $primary-color;
        background-color: transparent;

        &[type=radio] {
          background-image: none;
          box-shadow: unset;
        }
      }
    }

    .form-check-label {
      position: unset;
      padding: 0;
      margin-bottom: 0;

      &:after {
        content: none;
      }

      &::before {
        content: none;
      }

      i {
        font-size: 18px;
        padding-right: 8px;

        [dir="rtl"] & {
          padding-right: 0;
          padding-left: 8px;
        }
      }

      span {
        font-size: 16px;
      }
    }
  }
}

.rtl-input {
  .form-check {
    &.form-check-inline {
      [dir="rtl"] & {
        padding: 0;
      }
    }

    &.form-switch {
      [dir="rtl"] & {
        padding-right: 40px;
      }

      .form-check-input {
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: -40px;
          float: right;
        }
      }
    }
  }
}

// Custom form
.radio-form {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;

  .form-check {
    min-height: 0;
    margin-bottom: 0;

    .form-check-label {
      margin-bottom: 0;
    }
  }
}

// default-form start
.basic-form {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);

    [dir="rtl"] & {
      right: 0;
      left: 12px;
    }

    &.stroke-icon {

      width: 25px;
      height: 25px;
      stroke: $dark-color;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);

      [dir="rtl"] & {
        right: unset;
        left: 12px;
      }
    }
  }
}

.form-check {
  margin-bottom: 0;
}

.theme-form {
  .custom-input {
    .row {
      .col {
        .row {
          &:last-child {
            label {
              margin: 0;
            }
          }
        }
      }
    }
  }

  &.dark-inputs {
    input::file-selector-button {
      border-radius: 30px;
    }
  }

  .form-floating {
    >label {
      [dir="rtl"] & {
        left: unset;
        right: 0;
      }
    }
  }
}

/**=====================
  2.18 Forms CSS Ends
==========================**/