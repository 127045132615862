// General variables
$theme-font-color: #242934;
$theme-body-font-color: #051A1A;
$theme-body-sub-title-color: #FFFFFF;
$body-color: #F5F5F5;  
$main-body-color: #F5F5F5;

// Theme colors variables 
$primary-color: #0984e3;
$secondary-color: #FE6A49;
$success-color: #00AC46;
$info-color : #173878; 
$warning-color: #FFAE1A;
$danger-color: #FE6A49; 
$light-color : #E6E9EB;
$badge-light-color: #52526c;
$light-gray : #ecf3fa; 
$light-widget-border: #EFEEFF; 
$light-semi-gray :#efefef;
$font-gray-color: #6C757D; 
$dark-light :$theme-font-color; 
$dark-gray: #898989;
$dark-color : #2c323f;
$dark-color-light: #2c323fb8; 
$gray-60 :#E6E9EB;
$card-body-color : #f9f9fa; 
$transparent-color: transparent;
$auth-bg-color: #fafafa;
$light : #fdfeff;
$semi-dark :#59667a;
$light-text: #999999;
$light-paragraph: #8F8F8F;
$light-background: #d7e2e9;
$light-font: rgba(27, 49, 85, 0.4);
$light-semi-font: $semi-dark;
$light-chartist-grid-color: #f6f9fd;
$pitch-color: #fe8a7d;
$color-scarpa: #4c5667;
$color-fiord: #455a64;
$black : #000;
$white: #fff;
$off-white: #F7F6FF;
$theme-medium-color: $main-body-color;
$dropdown-border-color: #918E99;
$light-primary: rgba($primary-color, 0.2);
$light-secondary: rgba($secondary-color, 0.3);
$light-success: rgba($success-color, 0.3);
$light-info: rgba($info-color, 0.3);
$light-warning: rgba($warning-color, 0.3);
$light-danger: rgba($danger-color, 0.3);
$light-white: #F9F9FB;
$light-dark: #EAEBF2; 
$info-light: #44C6C6;
$light-txt-color : #86909C; 
// gradient colors 
$gradient-primary: linear-gradient(26deg, var(--theme-default) 0%, #006666 100%);
$gradient-bg-primary: linear-gradient(103.75deg, #173878 -13.9%, var(--theme-default) 79.68%);
$gradient-card-bg-primary: radial-gradient(circle, rgba(27,174,174,1) 2%, rgba(26,148,148,1) 50%, rgba(4,143,143,1) 100%);;
$gradient-landing-color : linear-gradient(180deg, #24B5B5 0%, #228585 100%);

// light colors 
$primary-light-color: #006666;
$secondary-light-color: #FE6A49;   
$warning-light-color: #FFAE1A; 
$danger-light-color: #FE6A49; 
$light-light-color : #E6E9EB; 

//social colors
$fb: #50598e;
$twitter: #6fa2d8;  
$google-plus: #c64e40;
$linkedin: #0077B5;

// soical table  
$fb-color: #2F84F4;
$insta-color: #FC971E;
$pintrest-color: $secondary-color;
$twitter-color: #57B9F6;
$youtube-color: #F42F2F; 

//error input color start
$light-body-bg-color: #fafdff;

//fonts
$font-Montserrat: 'Montserrat', sans-serif;
// $font-roboto: Roboto;
$font-serif: $font-Montserrat;
$landing-cursive: 'Caveat', cursive;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;

// dark layout variables
$dark-body-background: #1A1C23;
$dark-card-background: #22242B;
$dark-card-inbox: #323846;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgba(255, 255, 255, 0.6);
$light-all-font-color: rgba(255, 255, 255, 0.2);
$dark-border-color: $dark-body-background;
$dark-card-border: rgba(255, 255, 255, 0.10);
$dark-editor-document: #2b2b2b;
$bg-dark-color: #1f2533;
$dark-datatable-odd: #1f232b;
$dark-datatable-sorting: #1c212b;
$dark-datatable-sorting-even: #22262f;
$dark-even-hover-sorting: #191e27;

//General tag settings
$body-font-size: 14px;
$body-font-color: $theme-body-font-color;
$ul-padding-left: 0px;
$ancher-color: var(--theme-default);
$btn-focus-box-shadow: none;
$all-focus-outline-color: var(--theme-default);

$paragraph-font-size : 14px;
$paragraph-line-height : 1.7;
$paragraph-letter-spacing : 0.7px;

$code-tag-color: var(--theme-default);
$code-tag-bg-color: rgba($primary-color, 0.03);
$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;

$blockquote-padding : 8px;
$blockquote-border : 4px solid $primary-color;

$small-tag-padding: 10px;
$small-tag-color: $dark-color;

$pre-tag-bg-color: rgba($primary-color, 0.03);
$pre-tag-padding: 20px 0;

$list-group-active-bg-color: var(--theme-default);
$list-group-active-border-color: var(--theme-default);
$list-group-img-size: 40px;
$list-group-margin: 10px;


// Tables settings
$table-b-margin: 0px;
$table-heading-color: $theme-body-font-color;
$table-row-color: $theme-body-font-color;
$table-footer-font-weight: bold;
$table-inverse-color: $white;
$table-hover-color: $light;
$table-padding: 0.75rem;
$table-xl-padding: 1.25rem 2rem;
$table-xl-font: 120%;
$table-lg-padding: .9rem 2rem;
$table-lg-font: 110%;
$table-de-padding: .75rem 2rem;
$table-de-font: 100%;
$table-sm-padding: .5rem 2rem;
$table-sm-font: 90%;
$table-xs-padding: 0.2rem 2rem;
$table-xs-font: 80%;
$horizontal-border-color: #f2f4ff;
$horizontal-padding: .75rem 2rem;
$table-inverse-bg-color: #292b2c;

//According setting
$according-card-top-margin : 10px;
$according-btn-weight : 500;
$according-btn-color : $theme-body-font-color;
$according-card-header-padding : 0.75rem 1.25rem;
$according-card-header-icon : $font-ICO;
$according-open-icon : "\eb73";
$according-close-icon : "\eb7a";

//Alert settings
$alert-hover-color :$dark-color;
$alert-border-radious :0.15rem;
$alert-padding :15px;
$alert-msg-icon-size :16px;

//badge settings
$badge-padding :8px 16px;
$badge-svg-size :16px;

//form input settings
$form-group-margin-bottom : 1.25em;
$col-form-label-font-size : 14px;
$form-control-font-size : 14px;
$form-control-border-radious : 2px;
$form-control-border-color : $light-gray;

//breadcrumb setting
$breadcrumb-ancher-color : $white;
$breadcrumb-ancher-dark-color : $black;

//buttons setting
$btn-font-size : 14px;
$btn-padding : 0.375rem 1.75rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding: 0.05rem 0.4rem;

//Card settings
$card-padding : 20px;
$card-margin-bottom : 25px;
$card-border-color :1px solid $light-gray;
$card-border-radious :8px;
$common-shadow-color : rgba($info-color, 0.08);
$card-box-shadow : 0px 9px 20px rgba(46, 35, 94, 0.07);
$card-header-bg-color : $white;
$card-header-font-transform : capitalize;
$card-header-span-size : 12px;
$card-header-span-color : $theme-body-sub-title-color;
$card-body-bg-color : $transparent-color;
$card-footer-bg-color : $white;
$card-hover-box-shadow : 0 0 40px rgba(8, 21, 66, .05);



// According settings
$card-border-width :0px;
$card-header-font-weight : 600;
$card-header-font-size : 18px;
$card-header-font-color : $theme-body-font-color;

//footer settings
$footer_bg_color : $white;
$footer_box_shadow : 0 0 20px rgba($semi-dark, 0.1);
$footer_dark_color : $theme-font-color;
$footer_dark__txt_color : $white;
 
//form settings
$form-group-margin-bottom :20px;
$sm-form-margin-bottom : 14px;
$form-placeholder-color : $dark-gray;
$form-placeholder-font-size : 14px;
$form-placeholder-letter-spacing : 1px;
$form-placeholder-font-weight : 100;
$form-input-border-color : $light-semi-gray;
$form-input-bg-color : $white;

//Tour settings 
$tour-color: var(--theme-default);

//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-background-color: $white;
$sidebar-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
$sidebar-overflow: auto;
$sidebar-z-index: 9;
$sidebar-transition: 0.5s;
$sidebar-fill-icon-color: #757589;

//sidebar profile settings
$sidebar-profile-edit-icon-size: 14px;
$sidebar-profile-name-txt-color : var(--theme-default);
$sidebar-profile-name-txt-transfer : uppercase;
$sidebar-profile-name-txt-weight : 600;
$sidebar-profile-name-letter-specing : 1.5px;
$sidebar-profile-name-marging : 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0px;
$sidebar-profile-img-shadow: 0 0 15px rgba(68, 102, 242, 0.3);

//Sidebar main menu setting
$sidebar-menu-padding: 15px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;
$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-font-color: $theme-body-font-color;
$sidebar-padding-top: 12px;
$sidebar-padding-bottom: 12px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;
$sidebar-heading-hover-padding: 5px;
$sidebar-hover-txt-color: var(--theme-default);
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;
$sidebar-arrow-color: $theme-body-font-color;
$sidebar-open-icon: "\f107";
$sidebar-close-icon: "\f105";
$sidebar-icon-font-family: $font-awesome;
$sidebar-height: calc(100vh - 115px);
$sidebar-compact-width: 265px;

//Header settings
$main-header-position: fixed;
$main-header-top: 0;
$main-header-shadow: 0 0 20px rgba($semi-dark, 0.1);
$main-header-shadow-1: 0px 4px 40px rgba(39, 32, 120, 0.1);

//page settings
$page-body-padding : 25px 15px;
$page-body-bg-color: $light-color;
$page-body-margin-bottom: 0px; 
$page-title-padding : 20px 30px;
$common-box-shadow: 2px 3.464px 8px 0px rgba(22, 145, 248, 0.18);
$page-title-font-size: 24px;
$page-title-margin-bottom: 0;
$page-title-text-tranform: capitalize;
$page-small-title-display: block;
$page-small-title-font-size: 12px;
$page-small-title-margin-bottom: 5px;
$page-small-title-text-transform: capitalize;
$page-small-title-text-color: $theme-body-sub-title-color;
$breadcrumb-size: 16px;
$breadcrumb-content: "/";


//main header left settings
$header-left-bg-color : $white;
$main-header-padding : 27px 22px;
$main-header-z-index : 8;
$header-wrapper-padding: 13px 30px;
$header-wrapper-nav-right : 0px;
$header-wrapper-nav-icon-size : 20px;
$header-wrapper-nav-icon-color : $theme-font-color;
$header-wrapper-nav-icon-align : middle;
$header-size: 136px;
$box-layout-space : 40px;

// typography variables
$heading_1: 28px;
$heading_2: 26px; 
$heading_3: 24px; 
$heading_4: 20px;
$heading_5: 16px; 
$heading_6: 15px; 
$common_letter_spacing: 0.6px;


// widgets variables
$widget-shadow: 1px 2px 21px -2px rgba(214, 214, 227, 0.83);